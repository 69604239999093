<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card v-if="documentosadd">
      <template>
        <!-- <v-subheader>Datos Personales</v-subheader> -->
        <v-container fluid>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <input
              v-model="editedItem.id"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <label>Nombre</label>
                <v-text-field
                  v-model="editedItem.name"
                  solo
                  label="Nombre"
                  :rules="[v => !!v || 'Campo requerido']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <label>Categoría</label>
                <v-overflow-btn
                  v-model="editedItem.categoria"
                  label="Categoría"
                  attach
                  solo
                  editable
                  :rules="[v => !!v || 'Campo requerido']"
                  :items="
                    documentos.map(function(item) {
                      return {
                        text: item.name,
                        value: item.id
                      };
                    })
                  "
                  :menu-props="{maxHeight:200 }"
                  clearable
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-file-input
                  v-model="editedItem.file"
                  label="Seleccione documento"
                  accept="application/pdf"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-row>
                <v-spacer />
                <v-btn
                  color="#F6F6F6"
                  style="color:black;"
                  @click="volver"
                >
                  {{ $t("close") }}
                </v-btn>
                <v-btn
                  class="mr-4"
                  color="primary"
                  :loading="loading"
                  @click="submit"
                >
                  {{ $t("save") }}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-container>
      </template>
    </v-card>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'AddDocuments',
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      editedIndex: {
        type: Number,
        default: 0,
      },
    },
    data: vm => {
      return {
        tab: null,
        valid: true,
        search: '',
        reset: false,
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        edad: [
          v => {
            if (!isNaN(parseFloat(v)) && v >= 0) return true
            return 'Ingresa un número'
          },
        ],
        editedItem: {
          id: '',
          name: '',
          categoria: '',
          file: '',
        },
      }
    },
    computed: {
      documentosadd: {
        get () {
          return this.$store.state.documentos.documentosadd
        },
      },
      documentos: {
        get () {
          return this.$store.state.documentos.documentosFilter
        },
      },

      loading: {
        get () {
          return this.$store.state.documentos.loading
        },
      },
    },
    created () {
      this.$store.dispatch('documentos/getItemsDocumentosFilter')
    },
    async beforeMount () {
      await this.$store.commit('documentos/setRegister', false)
    },
    methods: {
      volver () {
        this.$refs.form.reset()
        this.$store.commit('documentos/setRegister', false)
      },
      submit () {
        if (this.$refs.form.validate()) {
          // const data = {
          //   nombre: this.editedItem.name,
          //   id_padre: this.editedItem.id_padre,
          // }
          var value = this.editedItem.file

          const formData = new FormData()
          formData.append('id', this.editedItem.id)
          formData.append('_file', this.editedItem.file)
          formData.append('name', this.editedItem.name)
          formData.append('categoria', this.editedItem.categoria)

          if (this.editedIndex < 0) {
            if (!value) {
              Vue.notify({
                group: 'loggedIn',
                text: 'Debes seleccionar un documento',
                type: 'teal accent-3',
                duration: 5000,
              })
              return
            }
            this.$store.dispatch('documentos/addDocumento', formData)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            this.$store.dispatch('documentos/updateDocumento', formData)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}
</style>
